<template>
  <div id="main_content">
    <section class="banner banner-three">
      <div class="header-video">
        <video src="@/assets/mikmic/christmas.mp4" autoplay loop></video>
      </div>
      <div class="container">
        <div class="banner-content-wrap-two">
          <div class="banner-content text-center">
            <div>
              <img src="@/assets/mikmic/mikmic-1.png" class="wow pixZoomIn" alt="mpckup" />
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div>
          <div class="banner-content text-center">
            <div class="col-lg-4 col-md-5"></div>
            <h3>{{ $t('click_to_system') }}</h3>
            <br />
            <div class="banner-button-container">
              <a href="https://system.mikmic.com/">
                <button
                  href="https://system.mikmic.com/"
                  class="custom-button pulse-button-custom"
                >Login</button>
              </a>

              <a href="https://system.mikmic.com/reg/new" style="margin-left: 10px;">
                <button
                  href="https://system.mikmic.com/reg/new"
                  class="custom-button pulse-button-custom"
                >Registrate</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="featured-four-ab">
      <div class="container">
        <div class="section-title text-center">
          <h3 class="sub-title wow pixFadeUp">Direcciones</h3>
          <br />
          <div id="testimonial-wrapper">
            <div
              class="swiper-container wow pixFadeUp"
              data-wow-delay="0.5s"
              id="testimonial-two"
              data-speed="700"
              data-autoplay="5000"
              data-perpage="2"
              data-space="50"
              data-breakpoints="{'991': {'slidesPerView': 1}}"
            >
              <swiper class="swiper" :options="swiperOption">
                <swiper-slide>
                  <div class="testimonial">
                    <div class="single-bio-thumb">
                      <img class="avatar" src="@/assets/countries/mexico.png" alt="testimonial" />
                    </div>
                    <div class="bio-info">
                      <h3 class="name">México</h3>
                      <span class="job">Pleyades 126, Malecon, Ocotlan Jalisco 47860</span>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="testimonial">
                    <div class="single-bio-thumb">
                      <img class="avatar" src="@/assets/countries/argentina.png" alt="testimonial" />
                    </div>
                    <div class="bio-info">
                      <h3 class="name">Argentina</h3>
                      <span
                        class="job"
                      >Laminar Catalinas, Puerto Madero, 240 Ing. Butty, Piso 4 y 5, Buenos Aires, C1002AFB</span>
                    </div>
                  </div>
                </swiper-slide>

                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="featured-four-ab">
      <div class="container">
        <div class="section-title text-center">
          <h3 class="sub-title wow pixFadeUp">{{ $t('our_team') }}</h3>
          <br />
          <div id="testimonial-wrapper">
            <div
              class="swiper-container wow pixFadeUp"
              data-wow-delay="0.5s"
              id="testimonial-two"
              data-speed="700"
              data-autoplay="5000"
              data-perpage="2"
              data-space="50"
              data-breakpoints="{'991': {'slidesPerView': 1}}"
            >
              <swiper class="swiper" :options="swiperOption">
                <swiper-slide>
                  <div class="testimonial">
                    <div class="single-bio-thumb">
                      <img class="avatar" src="@/assets/representates/mario.jpg" alt="testimonial" />
                    </div>
                    <div class="bio-info">
                      <h3 class="name">Mario González</h3>
                      <span class="job">{{$t('president')}}</span>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="testimonial">
                    <div class="single-bio-thumb">
                      <img class="avatar" src="@/assets/representates/maximo.jpg" alt="testimonial" />
                    </div>
                    <div class="bio-info">
                      <h3 class="name">Máximo Esquivel</h3>
                      <span class="job">{{$t('argentina_owner')}}</span>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="testimonial">
                    <div class="single-bio-thumb">
                      <img
                        class="avatar"
                        src="@/assets/representates/frutos.jpeg"
                        alt="testimonial"
                      />
                    </div>
                    <div class="bio-info">
                      <h3 class="name">Hugo Frutos</h3>
                      <span class="job">{{$t('general_counter')}}</span>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="testimonial">
                    <div class="single-bio-thumb">
                      <img class="avatar" src="@/assets/representates/alan.jpeg" alt="testimonial" />
                    </div>
                    <div class="bio-info">
                      <h3 class="name">Alan Cervantes</h3>
                      <span class="job">{{$t('legal_department')}}</span>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="testimonial">
                    <div class="single-bio-thumb">
                      <img
                        class="avatar"
                        src="@/assets/representates/daniel.jpeg"
                        alt="testimonial"
                      />
                    </div>
                    <div class="bio-info">
                      <h3 class="name">Daniel Adame</h3>
                      <span class="job">{{$t('legal_department')}}</span>
                    </div>
                  </div>
                </swiper-slide>

                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>-->

    <section class="about">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div>
              <div class="section-title text-center">
                <h3 class="sub-title wow pixFadeUp">{{ $t('logistic_platform') }}</h3>
                <h2 class="title wow pixFadeUp" data-wow-delay="0.3s">MikMic</h2>
              </div>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{ $t('home.p1.description') }}</p>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{ $t('home.p2.description') }}</p>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{ $t('home.p3.description') }}</p>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{ $t('home.p4.description') }}</p>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{ $t('home.p5.description') }}</p>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{ $t('home.p6.description') }}</p>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{ $t('home.p7.description') }}</p>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{ $t('home.p8.description') }}</p>
              <p class="description wow pixFadeUp text-justify" data-wow-delay="0.4s">
                {{ $t('home.p8.link_1') }}
                <a
                  href="https://www.youtube.com/watch?v=Sod5sWDetOQ"
                >{{ $t('url_customer_video') }}</a>
              </p>
              <p class="description wow pixFadeUp text-justify" data-wow-delay="0.4s">
                {{ $t('home.p9.link_2') }}
                <a
                  href="https://www.youtube.com/watch?v=Sod5sWDetOQ"
                >{{ $t('url_driver_video') }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="featured-four-ab">
      <div class="container">
        <div class="section-title text-center">
          <h3 class="sub-title wow pixFadeUp">{{ $t('our_advantage') }}</h3>
          <br />
          <div id="testimonial-wrapper">
            <div
              class="swiper-container wow pixFadeUp"
              data-wow-delay="0.5s"
              id="testimonial-two"
              data-speed="700"
              data-autoplay="5000"
              data-perpage="2"
              data-space="50"
              data-breakpoints="{'991': {'slidesPerView': 1}}"
            >
              <swiper class="swiper" :options="swiperOption">
                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div>
                      <img src="@/assets/mikmic/amount_service.jpeg" class="fit-picture" />
                    </div>
                    <div class="pixsass-icon-box-content">
                      <h3 class="pixsass-icon-box-title">{{ $t('features.title.reports') }}</h3>
                      <p>{{ $t('features.description.reports') }}</p>
                    </div>
                    <svg
                      class="layer"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="370px"
                      height="270px"
                    >
                      <path
                        fill-rule="evenodd"
                        fill="rgb(253, 248, 248)"
                        d="M-0.000,269.999 L-0.000,-0.001 L370.000,-0.001 C370.000,-0.001 347.889,107.879 188.862,112.181 C35.160,116.338 -0.000,269.999 -0.000,269.999 Z"
                      />
                    </svg>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div>
                      <img src="@/assets/mikmic/chat.jpeg" class="fit-picture" />
                    </div>
                    <div class="pixsass-icon-box-content">
                      <h3 class="pixsass-icon-box-title">{{ $t('features.title.chat') }}</h3>
                      <p>{{ $t('features.description.chat') }}</p>
                    </div>
                    <svg
                      class="layer"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="370px"
                      height="270px"
                    >
                      <path
                        fill-rule="evenodd"
                        fill="rgb(253, 248, 248)"
                        d="M-0.000,269.999 L-0.000,-0.001 L370.000,-0.001 C370.000,-0.001 347.889,107.879 188.862,112.181 C35.160,116.338 -0.000,269.999 -0.000,269.999 Z"
                      />
                    </svg>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div>
                      <img src="@/assets/mikmic/route_service.jpeg" alt />
                    </div>
                    <div class="pixsass-icon-box-content">
                      <h3
                        class="pixsass-icon-box-title"
                      >{{ $t('features.title.real_time_tracking') }}</h3>
                      <p>{{ $t('features.description.real_time_tracking') }}</p>
                    </div>
                    <svg
                      class="layer"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="370px"
                      height="270px"
                    >
                      <path
                        fill-rule="evenodd"
                        fill="rgb(253, 248, 248)"
                        d="M-0.000,269.999 L-0.000,-0.001 L370.000,-0.001 C370.000,-0.001 347.889,107.879 188.862,112.181 C35.160,116.338 -0.000,269.999 -0.000,269.999 Z"
                      />
                    </svg>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div>
                      <img src="@/assets/mikmic/profile.png" alt />
                    </div>
                    <div class="pixsass-icon-box-content">
                      <h3 class="pixsass-icon-box-title">{{ $t('features.title.trip_and_chat') }}</h3>
                      <p>{{ $t('features.description.trip_and_chat') }}</p>
                    </div>
                    <svg
                      class="layer"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="370px"
                      height="270px"
                    >
                      <path
                        fill-rule="evenodd"
                        fill="rgb(253, 248, 248)"
                        d="M-0.000,269.999 L-0.000,-0.001 L370.000,-0.001 C370.000,-0.001 347.889,107.879 188.862,112.181 C35.160,116.338 -0.000,269.999 -0.000,269.999 Z"
                      />
                    </svg>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div>
                      <img src="@/assets/mikmic/route.png" alt />
                    </div>
                    <div class="pixsass-icon-box-content">
                      <h3
                        class="pixsass-icon-box-title"
                      >{{ $t('features.title.jobs_drivers_schedule') }}</h3>
                      <p>{{ $t('features.description.jobs_drivers_schedule') }}</p>
                    </div>
                    <svg
                      class="layer"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="370px"
                      height="270px"
                    >
                      <path
                        fill-rule="evenodd"
                        fill="rgb(253, 248, 248)"
                        d="M-0.000,269.999 L-0.000,-0.001 L370.000,-0.001 C370.000,-0.001 347.889,107.879 188.862,112.181 C35.160,116.338 -0.000,269.999 -0.000,269.999 Z"
                      />
                    </svg>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div>
                      <img src="@/assets/mikmic/reputation.png" alt />
                    </div>
                    <div class="pixsass-icon-box-content">
                      <h3 class="pixsass-icon-box-title">{{ $t('features.title.trip_templates') }}</h3>
                      <p>{{ $t('features.description.trip_templates') }}</p>
                    </div>
                    <svg
                      class="layer"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="370px"
                      height="270px"
                    >
                      <path
                        fill-rule="evenodd"
                        fill="rgb(253, 248, 248)"
                        d="M-0.000,269.999 L-0.000,-0.001 L370.000,-0.001 C370.000,-0.001 347.889,107.879 188.862,112.181 C35.160,116.338 -0.000,269.999 -0.000,269.999 Z"
                      />
                    </svg>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div>
                      <img src="@/assets/mikmic/travel.png" alt />
                    </div>
                    <div class="pixsass-icon-box-content">
                      <h3
                        class="pixsass-icon-box-title"
                      >{{ $t('features.title.extra_certifications_and_permits') }}</h3>
                      <p>{{ $t('features.description.extra_certifications_and_permits') }}</p>
                    </div>
                    <svg
                      class="layer"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="370px"
                      height="270px"
                    >
                      <path
                        fill-rule="evenodd"
                        fill="rgb(253, 248, 248)"
                        d="M-0.000,269.999 L-0.000,-0.001 L370.000,-0.001 C370.000,-0.001 347.889,107.879 188.862,112.181 C35.160,116.338 -0.000,269.999 -0.000,269.999 Z"
                      />
                    </svg>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div>
                      <img src="@/assets/mikmic/history.png" alt />
                    </div>
                    <div class="pixsass-icon-box-content">
                      <h3 class="pixsass-icon-box-title">{{ $t('features.title.generate_routes') }}</h3>
                      <p>{{ $t('features.description.generate_routes') }}</p>
                    </div>
                    <svg
                      class="layer"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="370px"
                      height="270px"
                    >
                      <path
                        fill-rule="evenodd"
                        fill="rgb(253, 248, 248)"
                        d="M-0.000,269.999 L-0.000,-0.001 L370.000,-0.001 C370.000,-0.001 347.889,107.879 188.862,112.181 C35.160,116.338 -0.000,269.999 -0.000,269.999 Z"
                      />
                    </svg>
                  </div>
                </swiper-slide>

                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="featured-four-ab">
      <div class="container">
        <div class="section-title text-center">
          <h3 class="sub-title wow pixFadeUp">{{ $t('guide_videos') }}</h3>
          <br />
          <div id="testimonial-wrapper">
            <div
              class="swiper-container wow pixFadeUp"
              data-wow-delay="0.5s"
              id="testimonial-two"
              data-speed="700"
              data-perpage="2"
              data-space="50"
              data-breakpoints="{'991': {'slidesPerView': 1}}"
            >
              <swiper class="swiper" :options="swiperVideosOption">
                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div class="pixsass-icon-box-content">
                      <h3 class="pixsass-icon-box-title">{{ $t('user_register_video') }}</h3>
                    </div>
                    <div>
                      <iframe
                        width="auto"
                        height="315"
                        src="https://www.youtube.com/embed/BMlxCwo4_K0"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div class="pixsass-icon-box-content">
                      <h3 class="pixsass-icon-box-title">{{ $t('user_verify') }}</h3>
                    </div>
                    <div>
                      <iframe
                        width="auto"
                        height="315"
                        src="https://www.youtube.com/embed/KlcusGxdl34"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div class="pixsass-icon-box-content">
                      <h3 class="pixsass-icon-box-title">{{ $t('customer_video_title') }}</h3>
                    </div>
                    <div>
                      <iframe
                        width="auto"
                        height="315"
                        src="https://www.youtube.com/embed/vFeRxNIA4Ms"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div
                    class="saaspik-icon-box-wrapper style-four wow pixFadeLeft"
                    data-wow-delay="0.9s"
                  >
                    <div class="pixsass-icon-box-content">
                      <h3 class="pixsass-icon-box-title">{{ $t('video_title_driver') }}</h3>
                    </div>
                    <div>
                      <iframe
                        width="auto"
                        height="315"
                        src="https://www.youtube.com/embed/-IMge1gGmTI"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </swiper-slide>

                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="featured-four-ab">
      <div class="container">
        <div class="section-title text-center">
          <h3 class="sub-title wow pixFadeUp">{{ $t('download_now') }}</h3>
          <br />

          <h1 class="wow pixFadeUp">{{ $t('MIKMIC') }}</h1>
          <br />

          <div class="banner-button-container">
            <img src="@/assets/mikmic/mikmic_store_image.png" />
          </div>

          <div class="banner-button-container">
            <a href="https://play.google.com/store/search?q=mikmic&c=apps">
              <img src="@/assets/mikmic/google-store.png" class="button-store" />
            </a>

            <a
              href="https://apps.apple.com/mx/app/mikmic/id1673212928"
              class="button-store-position"
            >
              <img src="@/assets/mikmic/apple-store.png" class="button-store" />
            </a>
            <h6 class="wow pixFadeUp">{{ $t('register_mark') }}</h6>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "magnific-popup";
export default {
  name: "home",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 50,
        slidesPerGroup: 1,
        loop: true,
        speed: 700,
        direction: "horizontal",
        watchSlidesVisibility: !0,
        slideVisibleClass: "swiper-slide-visible",
        breakpoints: { 991: { slidesPerView: 2 } },
        autoplay: { delay: 5000, disableOnInteraction: false },
        pagination: { el: ".swiper-pagination", clickable: !0 },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      swiperVideosOption: {
        slidesPerView: 1,
        spaceBetween: 50,
        slidesPerGroup: 1,
        loop: true,
        direction: "horizontal",
        watchSlidesVisibility: !0,
        slideVisibleClass: "swiper-slide-visible",
        breakpoints: { 991: { slidesPerView: 2 } },
        pagination: { el: ".swiper-pagination", clickable: !0 },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  mounted() {
    window.$(".popup-video").magnificPopup({
      disableOn: 700,
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,

      fixedContentPos: false
    });
  },
  computed: {}
};
</script>